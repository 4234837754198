






















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Badge: () => import('@/components/TeamsBar/Badge.vue'),
    IconArrow: () => import('@tada/icons/dist/IconArrow.vue'),
  },
})
export default class Btn extends Vue {
  @Prop({ type: String, required: true }) private readonly dir!: 'up' | 'down'
  @Prop({ type: Number }) private readonly count!: number
  @Prop({ type: Boolean }) private readonly noticed!: boolean
}
